(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

(function () {
  "use strict";

  var formFields = require("./modules/interface.init.js");

  formFields();

  require("./modules/settings");
  require("./modules/registers");
  require("./modules/pilgrimTable");
  require("./modules/pilgrimDetails");
  require("./modules/roomingTemplate");

  $(".lightbox").prettyPhoto({
    social_tools: ""
  });
})(jQuery);

},{"./modules/interface.init.js":3,"./modules/pilgrimDetails":4,"./modules/pilgrimTable":5,"./modules/registers":6,"./modules/roomingTemplate":8,"./modules/settings":9}],2:[function(require,module,exports){
/*eslint no-unused-vars:0*/
"use strict";

module.exports = {
  getBasicPilgrims: function getBasicPilgrims(queryVars, callback) {
    "use strict";

    var pilgrimRequest = {
      "basic_profile": "yes",
      "pilgrim_ids": [],
      "bus_number": queryVars.busnumber,
      "flight_code": queryVars.flight,
      "gender": queryVars.gender,
      "package": queryVars.tourPackage,
      "room_type": queryVars.roomType
    };

    return $.ajax({
      url: baseUrl + "/admin/pilgrims",
      type: "post",
      beforeSend: function beforeSend(xhr) {
        return xhr.setRequestHeader("X-CSRF-TOKEN", token);
      },
      data: JSON.stringify(pilgrimRequest),
      dataType: "json"
    });
  }
};

},{}],3:[function(require,module,exports){
"use strict";

module.exports = function () {

  "use strict";

  $("select.richSelect").selectize({
    onInitialize: function onInitialize() {
      var s = this;
      this.revertSettings.$children.each(function () {
        $.extend(s.options[this.value], $(this).data());
      });
    },
    onOptionAdd: function onOptionAdd(value, data) {
      $.extend(this.options[value.value], data.companions);
    }
  }).find("input").attr("autocomplete", "false");
  $("select.richMultiSelect").selectize({
    maxItems: 20
  });
};

},{}],4:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  if ($(".pilgrim-info-block").length > 0) {
    $(".pilgrim-info-block:not(:first)").find(".pilgim-info-container").hide().end().find("h2").addClass("-is-section-closed");
  }

  $(".pilgrim-info-block h2").on("click", function () {
    $(this).toggleClass("-is-section-closed").next(".pilgim-info-container").toggle();
  });
})(jQuery);

},{}],5:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";
  var pilgrimTable = $("#pilgrim_table").DataTable({
    scrollY: 400,
    responsive: true,
    "language": {
      "lengthMenu": "Show _MENU_ per page",
      "search": "",
      "searchPlaceholder": "Search..."
    },
    "dom": "<\"dataTable-search group\"fl><\"bottom\"rtp>"
  });

  pilgrimTable.columns().flatten().each(function (colIdx) {
    if (colIdx > 0 && colIdx < pilgrimTable.columns().flatten().length - 1) {
      var select = $("<select />").appendTo(pilgrimTable.column(colIdx).footer()).on("change", function () {
        pilgrimTable.column(colIdx).search($(this).val()).draw();
      });

      select.append("<option value=\"\">All</option>");

      pilgrimTable.column(colIdx).cache("search").sort().unique().each(function (d) {
        select.append($("<option value=\"" + d + "\">" + d + "</option>"));
      });
    }
  });

  $("#pilgrim_table").on("click", "td:not(.select)", function () {
    if ($(this).parent().data("url") !== undefined) {
      document.location.href = $(this).parent().data("url");
    }
  });
  $('.deletemultiple').on('submit', 'form', function (e) {
    e.preventDefault();
    var rooms = $("#pilgrim_table input[name=\"roomid\"]:checked").map(function () {
      return this.value;
    }).get().join(',');
    $('.deletemultiple form').attr('action', $('.deletemultiple form').attr('action') + '/' + rooms);
    console.log();
  });
})(jQuery);

},{}],6:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var apiInterface = require("./apiinterface"),
      pilgrims = {},
      pilgrimSelection = {},
      $selectTemplate = $("#register-row-preview"),
      $previewTable = $("#pilgrim-list");

  $.views.settings.delimiters("{?", "?}");

  var getRegisterPilgrims = function getRegisterPilgrims() {
    var gender = $("#gender").val(),
        flight = $("#flight").val(),
        busnumber = $("#busnumber").val(),
        tourPackage = $("#package").val(),
        roomType = $("#room_type").val();

    var queryVars = {
      "gender": gender !== "" ? gender : null,
      "flight": flight !== "" ? parseInt(flight) : null,
      "busnumber": busnumber !== "" ? parseInt(busnumber) : null,
      "tourPackage": tourPackage !== "" ? parseInt(tourPackage) : null,
      "roomType": roomType !== "" ? parseInt(roomType) : null
    };

    apiInterface.getBasicPilgrims(queryVars).done(function (pilgrimResult) {
      pilgrimSelection = pilgrimResult;

      $("#register-pilgrim-total").text(pilgrimSelection.length);

      $previewTable.append($selectTemplate.render(pilgrimSelection));
      console.log(pilgrimSelection);
    });
  };

  if ($(".pilgrim-filters").length > 0) {

    getRegisterPilgrims();

    $("#gender, #flight, #busnumber, #package, #room_type").on("change", function () {
      getRegisterPilgrims();
    });
  }
  // var gender = $("#gender");
})(jQuery);

},{"./apiinterface":2}],7:[function(require,module,exports){
"use strict";

module.exports = function ($target) {
  "use strict";

  var $table = $target.find("tbody");

  if ($table.length > 0) {

    var $template = $($target.data("template")),
        startData = JSON.parse($($target.data("source")).html()),
        formFields = require("./interface.init.js");

    $.views.settings.delimiters("{?", "?}");

    $table.append($template.render(startData));

    formFields();

    var addRow = function addRow() {
      $table.append($template.render({ number: "" }));

      formFields();
      $table.find("input.repeaterInput").last().focus();
    };

    $target.find(".addRow").on("click", addRow);

    $table.on("click", ".remove", function () {
      $(this).parents("tr").remove();
      return false;
    });

    $table.on("keypress", "input, select", function (e) {
      var code = e.keyCode || e.which;
      if (code === 13) {
        e.preventDefault();
        e.stopPropagation();
        addRow();
      }
    });
  }
};

},{"./interface.init.js":3}],8:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var $target = $("#roomTable");
  var $parent = $("#roomTemplateTable");

  var $table = $target.find("tbody");

  var unroomedPilgrims = [];

  if ($table.length > 0) {

    var $template = $($target.data("template")),
        startData = JSON.parse($($target.data("source")).html()),
        formFields = require("./interface.init.js"),
        $pilgrimTemplate = $($target.data("subtemplate"));

    $.views.settings.delimiters("{?", "?}");
    $.views.settings.allowCode = true;

    $table.append($template.render(startData));

    var initPilgrimLists = function initPilgrimLists() {
      updatePilgrims();
      tips();
      var $selects = $parent.find("select.pilgrim-select, .template-room-pilgrims select.richSelect");

      $selects.each(function () {
        var _self = this.selectize,
            $elem = $(this).next(".selectize-control");

        this.selectize.off("change");

        this.selectize.on("change", function (e) {

          if (e.length !== 0) {
            updatePilgrims();
            tip(_self, $elem, e);
          }
        });
      });
    };

    var tips = function tips() {
      $parent.find("select.pilgrim-select, .template-room-pilgrims select.richSelect").each(function () {
        var _self = this.selectize,
            $elem = $(this).next(".selectize-control");

        var position = _self.getValue();

        tip(_self, $elem, position);
      });
    };

    var tip = function tip(select, $target, position) {
      if (select.options[position] != undefined) {
        if (!$target.hoverTip) {
          $target.hoverTip = new Opentip($target, "", "", {
            removeElementsOnHide: true,
            showOn: null,
            target: $target,
            offset: [0, -20]
          });
        }

        if (select.options[position].companions != undefined && select.options[position].companions.length > 0) {
          $target.hoverTip.options.showOn = "mouseover";
          $target.hoverTip.setContent("Companions: <ul><li>" + select.options[position].companions.join("</li><li>") + "</li></ul>");
          $target.on("mouseenter", function () {
            $target.hoverTip.show();
          }).on("mouseleave", function () {
            $target.hoverTip.hide();
          });
        } else {
          $target.off("mouseenter").off("mouseleave");
          $target.hoverTip.hide();
        }
        for (var i = 0; i < Opentip.tips.length; i++) {
          Opentip.tips[i].hide();
        }
      }
    };

    var addRow = function addRow(e) {
      var $wrap = $target.find(".pilgrim-row").parent(),
          extras = {
        "row_index": $wrap.parents(".settings-table-row").first().data("row_index") + 1 || 0,
        "pilgrim_index": 0
      };
      console.log(extras);

      $table.append($template.render({ number: "" }, extras));

      formFields();
      initPilgrimLists();
      $table.find("input.repeaterInput").last().focus();
    };

    var addPilgrim = function addPilgrim(e) {
      e.preventDefault();
      var $wrap = $(e.target).parents(".pilgrim-row").parent(),
          extras = {
        "row_index": $wrap.parents(".settings-table-row").first().data("row_index"),
        "pilgrim_index": $wrap.find(".pilgrim-row").length
      };
      $wrap.append($pilgrimTemplate.render({ "pilgrim_id": "" }, extras));

      formFields();
      initPilgrimLists();
      $wrap.find("input.repeaterInput").last().focus();
    };

    var updatePilgrims = function updatePilgrims() {
      var tempUnroomedPilgrims,
          $selects = $(".template-room-pilgrims select.richSelect, select.pilgrim-select ");

      unroomedPilgrims = [];

      $selects.each(function () {
        var value = $(this).val();
        if (typeof value === "string" && value !== "") {
          // if(unroomedPilgrims.indexOf(value) === -1){
          unroomedPilgrims.push(value);
          // }
        }
      });

      var tempUnroomedPilgrims = window.allPilgrims.filter(function (pilgrim) {
        return unroomedPilgrims.indexOf(pilgrim.value.toString()) === -1;
      });

      $selects.each(function (index) {
        var value = $(this).val(),
            select = $(this)[0].selectize,
            options = [];

        select.settings.placeholder = "Select a pilgrim";

        options.push(tempUnroomedPilgrims);

        var selectedPilgrim = window.allPilgrims.filter(function (pilgrim) {
          return pilgrim.value.toString() === value;
        });

        if (selectedPilgrim[0] != undefined) {
          options.unshift(selectedPilgrim[0]);
        }
        options.unshift({ "value": "0", "text": "Select a pilgrim" });
        if (options) {
          select.clearOptions();
          select.addOption(options);
          select.refreshOptions(false);
          if (selectedPilgrim[0] != undefined) {
            select.setValue(selectedPilgrim[0].value, true);
            select.templates_refreshing = true;
          }
        }
      });
    };

    $target.on("click", ".addRow", addRow);
    $target.on("click", ".positive", addPilgrim);

    $table.on("click", ".remove", function () {
      $(this).parents("tr").first().remove();
      return false;
    });

    $table.on("click", ".negative", function () {
      $(this).parents(".pilgrim-row").first().remove();
      return false;
    });

    formFields();

    initPilgrimLists();
  }
})(jQuery);

},{"./interface.init.js":3}],9:[function(require,module,exports){
"use strict";

(function ($) {
  "use strict";

  var repeater = require("./repeater");

  repeater($("#busNumberTable"));
  repeater($("#locationTable"));
  repeater($("#hotelTable"));
  repeater($("#packageTable"));
  repeater($("#flightcodeTable"));
  repeater($("#roomTypeTable"));
  repeater($("#scheduledTourTable"));
})(jQuery);

},{"./repeater":7}]},{},[1]);
